<script setup lang="ts">
</script>

<template>
   <div class="flex min-h-screen flex-col bg-gray-50">
      <AppNav />
      <main class="flex-1">
         <NuxtPage />
      </main>
      <AppFooter />
   </div>
</template>
